'use strict';

angular.module('kljDigitalLibraryApp')

.controller('LoginCtrl', ["$rootScope", "$scope", "$state", "User", "Auth", "appConfig",
    "commonVariables", "$stateParams", "$anchorScroll", "$location", "AccountDetailsService",
    "SupportService", "ngToast",
    function($rootScope, $scope, $state, User, Auth, appConfig,
        commonVariables, $stateParams, $anchorScroll, $location, AccountDetailsService,
        SupportService, ngToast) {

        var init = function() {
            if (!$rootScope.offlineApp) {
                getOrganizations();
                saveIpDetails();
            }

        }

        $rootScope.title = "Login";
        $rootScope.currentUrl = $state.current.name;
        var currentUser = User.get().$promise;

        // if ($rootScope.offlineApp) {
        //     $state.go("index.home");

        // }

        currentUser.then(function(user) {
                $state.go("index.home");
            })
            .catch(function(error) {
                console.log("eroor", error);
            })

        $scope.user = {};

        $scope.registeredUser = function(mobile_number, valid) {
            $scope.onSubmit = true;
            //validPhoneNo($scope.user.mobile_number, /^\d{10}$/)
            if (valid) {

                Auth.registerdUser($scope.user.mobile_number)

                .then(function(data) {
                    $scope.onSubmit = false;
                    $scope.showOTP = true;
                    $scope.isregisteredUser = true;
                    $scope.generateOTP($scope.user.mobile_number);
                    showEmailOtpOptions();
                })

                .catch(function(error) {
                    $scope.onSubmit = false;
                    $scope.isregisteredUser = false;
                });
            } else {
                $scope.showOTP = false;
            }
        }

        var onSubmit = function(token) {
            console.log('success!', token);
            $scope.googleToken = token;
        };

        var onloadCallback = function() {
            grecaptcha.render('submit-captcha', {
                'sitekey': '6Ler9RoTAAAAAGIQw9A3ann6g5de5-MOWRPPxxSd',
                'callback': onSubmit
            });
        };
        // setTimeout(onloadCallback, 0)

        $scope.generateOTP = function() {

            // var captcha = grecaptcha.getResponse("6LesGxIUAAAAABPhBTPkTb9lu8ZK6lZH4j6qaBih");
            // console.log("captcha-->", grecaptcha.execute())
            if (true) {

                $scope.showOTP = $scope.sendOtpError = false;
                $scope.resendingOTP = true;

                Auth.generateOTP($scope.user.mobile_number, $scope.googleToken)

                .then(function(success) {

                    $scope.sendOtpError = $scope.resendingOTP = false;
                    $scope.showOTP = true;
                    // grecaptcha.reset();

                })

                .catch(function(error) {
                    console.log(error);
                    $scope.otpError = error.data;
                    $scope.sendOtpError = true;
                    $scope.showOTP = $scope.resendingOTP = false;
                    // grecaptcha.reset();
                });



            } else {

                $scope.showOTP = false;
            }
        }

        $scope.login = function() {
            var body = {
                user: $scope.user
            };
            $scope.wrongOtp = false;
            Auth.login(body)
                .then(function(user) {

                    $rootScope.username = user.first_name + " " + user.last_name;
                    $state.go("index.home");
                })

            .catch(function(error) {
                if (error == 'Please purchase or try any subscription and come back here.') {
                    //redirect to home 
                    $scope.unAuth = error;
                } else {
                    $scope.unAuth = false;
                    $scope.wrongOtp = true;
                }
            })
        }

        $scope.checkMobileNumber = function() {


        }

        $scope.defaultFlag = function() {

            $scope.isregisteredUser = undefined;

        }

        var validPhoneNo = function(phoneno, pattern) {

            return pattern.test(phoneno) ? true : false;

        }

        var getOrganizations = function() {
            AccountDetailsService.getList()
                .then(function(data) {
                    $scope.organizations = data;
                })
                .catch(function(error) {
                    console.log(error);
                });
        }

        if ($stateParams.contact_us) {
            $scope.isActive = true;
            $('html, body').animate({
                scrollTop: $("#contactForm").offset().top - 30
            }, 1000);
        } else {
            $scope.isActive = false;
        }

        angular.element(document).ready(function() {
            $('.login_panel').css('height', $(window).height() + "px");


            $('.log-btn').click(function() {
                $('.log-status').addClass('wrong-entry');
                $('.error_class').fadeIn(500);
            });

            $('.form-control').keypress(function() {
                $('.log-status').removeClass('wrong-entry');
            });
        });


        $(window).scroll(function() {
            if ($(window).width() > 767) {
                if ($(window).scrollTop() > 1) {
                    $(".menuholder").addClass("affix");
                    // alert('scroll');
                } else {
                    $(".menuholder").removeClass("affix");
                }
            }


        });

        // grecaptcha.getResponse = function(opt_widget_id){
        //   console.log("opt_widget_id-->", opt_widget_id);
        // }

        $scope.submitForm = function(valid, data) {
            $scope.submit = true;
            if (valid) {
                SupportService.createFeedback(data)
                    .then(function(response) {
                        $scope.submit = false;
                        $scope.feedback = {};
                        ngToast.success("Successfully submitted.");
                    })
                    .catch(function(error) {
                        ngToast.danger(error.data);
                    });
            }
        }

        $scope.redirectToHome = function() {
            $state.go('login', {
                contact_us: undefined
            });
            //Scroll top
            $(document).ready(function() {
                $(this).scrollTop(0);
            });
        }

        var saveIpDetails = function() {
            AccountDetailsService.storeIpAddress()
                .then(function(response) {
                    console.log("success");
                })
                .catch(function(error) {

                });
        }


        $scope.showEmailOtpFlag = false;
        function showEmailOtpOptions(){
            setTimeout(function(){ 
                $scope.$apply($scope.showEmailOtpFlag = true);
        }, 10000);
        }

        
        $scope.email_otp_status = false;

        $scope.sendOtpOnEmail = function(mobile_number){
            Auth.generateEmailOTP(mobile_number)
             .then(function(success) {
                    $scope.$apply($scope.email_otp_status = true);
                    $scope.$apply($scope.showEmailOtpFlag = false);
                })

                .catch(function(error) {
                    console.log(error);
                   
                });

        }
        init();


    }
]);
